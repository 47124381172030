import Colors from "./Colors";

const DEFAULT_FONT = 'Nunito Sans, sans-serif';
export const DEFAULT_HEADER_FONT = "'Nunito Sans',Helvetica,Arial,Lucida,sans-serif";
export const DEFAULT_BODY_FONT = "'Lora',Georgia,'Times New Roman',serif"
export const DEFAULT_BUTTON_FONT = "'Roboto Condensed', Helvetica, Arial, Lucida, sans-serif !important"

export const PROFILE_FONT_STYLE = {
  fontFamily: DEFAULT_HEADER_FONT
}

export const CHIP_STYLE = {
  backgroundColor: Colors.primaryColor,
  borderRadius: '3px',
  fontFamily: DEFAULT_HEADER_FONT,
  textTransform: 'uppercase',
  fontSize: '15px',
  fontWeight: 500,
  color: '#fff'
}

export const CARD_HEADER_STYLE = {
  paddingTop: '16px',
  fontFamily: DEFAULT_HEADER_FONT,
  fontSize: '24px',
  fontWeight: 600
}

export const CARD_SUB_HEADER_STYLE = {
  fontFamily: DEFAULT_HEADER_FONT,
  fontSize: '18px',
  fontWeight: 500,
}

export const CARD_CAPTION_STYLE = {
  fontFamily: DEFAULT_HEADER_FONT,
  fontSize: '15px',
  fontWeight: 400,
  color: '#aaa',
  textTransform: 'uppercase'
}

export const FOOTER_STYLE = {
  fontSize: 14,
  marginTop: 16,
  fontFamily: DEFAULT_HEADER_FONT,
  color: Colors.darkGreyText,
  backgroundColor: '#fff',
  padding: '10px',
  boxShadow: '0px -3px 3px -2px rgba(0, 0, 0, 0.2)'
}

export const LINK_TEXT_STYLE = {
  color: Colors.primaryColor,
  textDecoration: 'underline',
  cursor: 'pointer'
}

export const TEXT_FIELD_STYLE = {
  textTransform:'none',
  fontSize:'20px',
  fontFamily: DEFAULT_BUTTON_FONT,
  fontWeight:'bold',
  color: '#555',
  borderRadius: 0
}

export const NOTIFICATION_BAR_STYLES = {
  fontFamily: DEFAULT_HEADER_FONT,
  fontSize: '16px',
  width: '100%'
}

export const CONTAINED_BUTTON_STYLE = {
  fontFamily: DEFAULT_BUTTON_FONT,
  borderRadius: 0,
  fontSize: '14px',
  letterSpacing: '2px',
  fontWeight: 600,
  padding: '12px 36px 12px 36px'
}

export const TAB_BUTTON_STYLE = {
  fontFamily: DEFAULT_BUTTON_FONT,
  borderRadius: 50,
  color: '#333333',
  fontSize: '14px',
  letterSpacing: '2px',
  fontWeight: 600,
  padding: '12px 36px 12px 36px',
  "&:hover": {
    backgroundColor: '#fff',
    color: "#555", // Change text color on hover
  },
}

export const NAV_TEXT_BUTTON_STYLE = {
  textTransform:'uppercase',
  fontSize:14,
  fontFamily: DEFAULT_BUTTON_FONT,
  fontWeight: 500,
  color: '#666',
  paddingTop: '12px',
  paddingBottom: '12px',
  letterSpacing: '1px'
}

export const TEXT_BUTTON_STYLE = {
  textTransform:'uppercase',
  fontSize:14,
  fontFamily: DEFAULT_BUTTON_FONT,
  fontWeight: 600,
  color: '#666',
  paddingTop: '12px',
  paddingBottom: '12px',
  letterSpacing: '1px'
}

export const MENU_ITEM_STYLE = {
  textTransform:'none',
  fontSize:18,
  fontWeight: 500,
  fontFamily: DEFAULT_HEADER_FONT,
  color: '#333'
}

export const TABLE_HEADER_STYLE = {
  paddingTop: '16px',
  fontFamily: DEFAULT_HEADER_FONT,
  fontSize: '14px',
  fontWeight: 600
}

export const TABLE_BODY_STYLE = {
  fontFamily: DEFAULT_BODY_FONT,
  fontSize: '14px'
}

export const SUMMARY_TITLE_FONT = {
  fontFamily: DEFAULT_HEADER_FONT,
  fontWeight: 600
}

export const ICON_TEXT_SECONDARY_FONT = {
  fontFamily: DEFAULT_BODY_FONT,
  fontSize: '16px',
}

export const ICON_TEXT_STYLE = {
  fontFamily: DEFAULT_BODY_FONT,
  fontSize: '18px'
}

export const SUBCONTENT_INFO_FONT = {
  fontFamily: DEFAULT_HEADER_FONT,
  fontWeight: 600,
  fontSize: '20px'
}

export const STANDARD_PARAGRAPH_STYLE = {
  fontFamily: DEFAULT_BODY_FONT,
  fontSize: '16px',
  textTransform: 'none'
}

export default {
  paper: {
    width: '100%',
    height: '100%',
  },
  innerPaperPadding: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 28,
    paddingRight: 28,
  },
  h1Text: {
    fontSize: 48,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  },
  h2Text: {
    fontSize: 36,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  },
  h3Text: {
    fontSize: 28,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  },
  h4Text: {
    fontSize: 20,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    width: '100%',
    color: Colors.defaultDarkText
  }
};
