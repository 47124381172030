import React from "react";
import { Chip, styled } from "@mui/material";
import Colors from "../../constants/Colors";

const StyledPercentageChip = ({ value, flip }) => {
  const backgroundColor = value >= 0 
    ? (flip ? Colors.redColor : Colors.greenColor) 
    : (flip ? Colors.greenColor : Colors.redColor);
  return (
    <Chip
      label={`${value}%`}
      sx={{
        backgroundColor, // Green for positive, red for negative
        color: "white",
        borderRadius: "16px", // Pill shape
        fontSize: "0.875rem", // Slightly smaller text
        fontWeight: "bold",
        padding: "0 4px",
        height: "24px",
      }}
    />
  );
};

export default StyledPercentageChip;
