
const blue = '#079B9B';
const white = '#FCFCFC';
const red = '#D00F00';
const orange = '#fa8d00';
const purple = '#3F1BB1';//'#1946AC';
/*
Green: #15D601 //  #00CB2D
Blue: #197BBD
Black: #34403A
Grey: #555358
White: #F3F9D2
Red: #ED254E
Orange: #FE7528
*/

export default {
  primaryColor: '#10AA00', // '#53c31b', //'#68B361', //'#00B929',
  secondaryColor: '#80973E', // grey,
  thirdColor: '#88CA6A',

  darkColor: '#10AA00', //'#909468', //'#1E252B',
  darkColorOffset: '#0D8B00',
  transparentDarkColor: 'rgba(144, 148, 104, 0.8)',
  greyColor: '#C5C7C6',
  lightGreyColor: '#E4E6E5',
  lightColor: white,
  midLightColor: '#DCDCDC',
  transparentLightColor: 'rgba(235, 235, 235, 0.75)',


  defaultText: '#2F3943',
  defaultLightText: '#747E88',
  greyText: '#C5C7C6',
  darkGreyText: '#858786',

  link: '#197BBD',

  loadingBackgroundColor: 'rgba(0, 203,45,0.8)',

  textboxBackgroundColor: '#FCFCFC',
  textboxBorderColor: '#EEE',

  successBackgroud: '#68B361', //green,
  successText: white,

  errorBackground: red,
  errorLightBackground: '#FFA49D',
  errorOutline: '#A10020',
  errorText: white,

  warningLightBackground: '#FFBE69',
  warningBackground: orange,
  warningBackgroundOffset: '#D07500',
  warningText: white,

  noticeLightOutline: '#53B6C6',
  noticeDarkOutline: '#1C3D42',
  noticeBackground: blue,
  noticeBackgroundOffset: '#007B7B',
  noticeText: white,

  lightShadowColor: '#FCFCFC',
  darkShadowColor: '#404E5C',

  purpleThemeColor: purple,

  greenColor: '#10AA00',
  yellowColor: '#FFD23F',
  orangeColor: '#F75C03',
  redColor: '#fc3f3f',
  blueColor: blue
};
