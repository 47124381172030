import { LOCAL_AVA_API } from "../../constants/System";

const BCK_AVA_API = LOCAL_AVA_API ? 'http://localhost:8008/v1/' : 'https://vblbi5kcx3.execute-api.us-east-2.amazonaws.com/prod/';

const __post = async (url, payload) => {
  try {
    return await fetch(url, {
      method: 'post',
      body : payload,
      // mode: 'no-cors'
    })
    .then(resp => {
      if (!resp.ok || resp.status >= 400) throw resp.json()
      return resp.json()
    })
    .catch(error => {
        throw error;
    })
  } catch (err) {
    throw err
  }
}

const __patch = async (url, payload) => {
  try {
    return await fetch(url, {
      method: 'PATCH',
      body : payload,
      //mode: 'no-cors'
    })
    .then(resp => {
      if (!resp.ok || resp.status >= 400) throw resp.json()
      return resp.json()
    })
    .catch(error => {
        throw error;
    })
  } catch (err) {
    throw err
  }
}

const __delete = async (url, payload) => {
  try {
    return await fetch(url, {
      method: 'DELETE',
      body : payload,
      //mode: 'no-cors'
    })
    .then(resp => {
      if (!resp.ok || resp.status >= 400) throw resp.json()
      return resp.json()
    })
    .catch(error => {
        throw error;
    })
  } catch (err) {
    throw err
  }
}

const __get = async (url) => {
  try {
    return await fetch(url)
    .then(resp => {
      if (!resp.ok || resp.status >= 400) {
        throw resp.json()
      }
      if (resp.status === 204) { // no content
        return null
      } 
      return resp.json()
    })
    .catch(error => {
        throw error
    })
  } catch (err) {
    throw err
  }
}


/***************************/
/***   ANALYTICS CALLS   ***/
/***************************/

export const fetchSiteAvaAnalytics = async (user_id, site_id, options) => {
  const payload = { user_id, site_id, options };
  return __post(BCK_AVA_API + `activity/query`, JSON.stringify(payload))
  .then(response => {
    if (response && response.data) return response.data;
    return response;
  })
  .catch(err => { throw err });
}
