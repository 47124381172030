import React, { useState } from "react";
import { Grid, Tooltip as MuiTooltip, Paper, Box, MenuItem } from '@mui/material';
import { InfoOutlined } from "@mui/icons-material";
import { capitalize, orderBy } from "lodash";
import { CartesianGrid, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getSiteAnalyticsV2 } from '../../../actions/internal/SiteActions';
import Colors from "../../../constants/Colors";
import GenericInfoCard from "./cards/GenericInfoCard";
import { getSiteAnalyticsV2ByIdAndRange } from '../../../selectors/SiteSelectors';
import StyledSubHeader from '../../common/StyledSubHeader';
import { StyledLoadingSection } from '../../common/StyledProgressCircle';
import { CARD_SUB_HEADER_STYLE } from '../../../constants/Styles';
import { ContainedButton, TabButton, TextButton } from '../../common/StyledButtons';
import { withRouter } from 'react-router';
import { getUserId } from "../../../selectors/UserSelectors";
import { calculateStartDate, formatDateToYYYYMMDD } from "../../../utilities/DateUtilities";
import StyledPercentageChip from "../../common/StyledPercentageChip";
import { StyledDropDown } from "../../common/StyledDropDown";
import { NOTIFICATION_TYPE, showNotificationBar } from "../../../actions/error-pages/NotificationBarActions";

const PastDayChoices = {
    PAST_7_DAYS: 7,
    PAST_30_DAYS: 30,
    PAST_60_DAYS: 60,
    PAST_90_DAYS: 90,
    PAST_120_DAYS: 120
}

const PastDayChoicesLabels = {
    [PastDayChoices.PAST_7_DAYS]: 'Last 7 Days',
    [PastDayChoices.PAST_30_DAYS]: 'Last 30 Days',
    [PastDayChoices.PAST_60_DAYS]: 'Last 60 Days',
    [PastDayChoices.PAST_90_DAYS]: 'Last 90 Days',
    [PastDayChoices.PAST_120_DAYS]: 'Last 120 Days'
}

const PageViews = {
    ANALYTICS: 'ANALYTICS',
    TRENDING: 'TRENDING',
    INSIGHTS: 'INSIGHTS'
}

const ACTIVE_USERS_INFO = "This tracks the number of people currently engaged with your website in real-time. This metric provides insights into current website traffic and user behavior, helping you understand peak activity periods and monitor live engagement trends.";
const NEW_USERS_INFO = "This is the measure of the number of first-time visitors to your site within a given period. It reflects your ability to attract fresh audiences. Monitoring new users is crucial for assessing the effectiveness of marketing efforts and driving growth.";
const AVG_TIME_INFO = "This indicates the average duration users spend on your website per session. This metric reflects user engagement and content relevance. Longer visit times typically suggest that users find your content valuable and engaging.";
const BOUNCE_RATE_INFO = 'This is the percentage of visitors leaving after viewing only one page highlights engagement issues. A high bounce rate signals poor user experience or irrelevant content, which can harm conversions and retention.';
const UPTIME_INFO= 'This is the current status and ratio of time your website is accessible over the past 30 days. It is vital for maintaining trust and preventing lost revenue. High uptime ensures a seamless user experience and protects your reputation.';
const INSIGHT_INFO = 'This is a summarization your website\'s key performance metrics over your selected time frame. This section highlights essential data such as traffic trends, engagement rates, uptime, and user activity, providing a clear overview of how your site has been performing at a glance.'

const ViewSiteActivityComponent = ({ history, match }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ pageInView, setPageInView ] = useState(PageViews.ANALYTICS);
    const [ pastDayView, setPastDayView ] = useState(PastDayChoices.PAST_30_DAYS);

    const siteId = match.params.site_id
    const analyticsV2 = getSiteAnalyticsV2ByIdAndRange(siteId, pastDayView)

    const changeDateRange = async (val) => {
        const previousDateRange = pastDayView;
        try {
            setIsLoading(true);
            setPastDayView(val);
            const date = new Date();
            // Add 1 to Month so that January = 0 is January = 1. Switches from index to calendar num
            const startDate = calculateStartDate(date.getFullYear(), date.getMonth() + 1, date.getDate(), val);
            const endDate = formatDateToYYYYMMDD(date);
            const defaultOptions = { startDate, endDate, includeRecentReports: true }
            await getSiteAnalyticsV2(getUserId(), siteId, defaultOptions, val);
        } catch (ex) {
            const err = await ex;
            console.error(err);
            setPastDayView(previousDateRange);
            showNotificationBar(NOTIFICATION_TYPE.ERROR, 'The data request could not be loaded at this time. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes < 10 ? '0' + String(minutes) : String(minutes)}:${seconds < 10 ? '0' + String(seconds) : String(seconds)}`
    }

    const renderLoadingContent = () => (
        <StyledLoadingSection />
    )

    const renderPercentageSection = (current_number, past_number, flip_value = false) => {
        if (past_number === 0) return renderPercentageIcon(0, flip_value);
        // const color = isNegative ? Colors.redColor : Colors.greenColor;
        let percentChange = current_number - past_number;
        // if (isNegative) percentChange *= -1;
        percentChange = percentChange / past_number * 100;
        return renderPercentageIcon(percentChange, flip_value)
    }

    const renderPercentageIcon = (percentage, flip_value = false) => {
        // const color = isNegative ? Colors.redColor : Colors.greenColor;
        return (
            <StyledPercentageChip value={(percentage).toFixed(1)} flip={flip_value} />
        )
    }

    const formatAnalyticsData = () => {
        const { TARGET, COMPARE } = analyticsV2.analytics;
    
        // Sort both TARGET and COMPARE by epoch time (descending order)
        const sortedTarget = orderBy(TARGET, ['epoch'], ['desc']);
        const sortedCompare = orderBy(COMPARE, ['epoch'], ['desc']);
    
        // Match TARGET with COMPARE by index (assumes one-to-one pairing)
        const formattedData = sortedTarget.map((target, index) => {
            const compare = sortedCompare[index] || {}; // Handle cases where COMPARE has fewer items
    
            return {
                name: target.date, // Use the date string from TARGET
                visitors: target.activeUsers, // Use activeUsers from TARGET
                visitors_previously: compare.activeUsers || 0, // Use activeUsers from COMPARE, default to 0
            };
        });
    
        return formattedData.reverse();
    }

    const renderVisitorChart = () => {
        return (
            <Box sx={{ paddingRight: '8%', marginTop: '50px' }}>
                <ResponsiveContainer width="100%" height={250}>
                    <LineChart width={800} height={300} data={analyticsV2 ? formatAnalyticsData() : []}>
                        <Line
                            type="monotone" // Use monotone for smooth, curved lines
                            dataKey="visitors"
                            stroke={Colors.primaryColor}
                            strokeWidth={2}
                            fill={Colors.primaryColor}
                            fillOpacity={0.05}
                            dot={({ cx, cy, payload }) => 
                                payload.visitors ? (
                                    <circle cx={cx} cy={cy} r={4} fill={Colors.primaryColor} />
                                ) : null
                            } // Render dots only for data points with "visitors"
                        />
                        <Line
                            type="monotone" // Smooth, curved line
                            dataKey="visitors_previously"
                            stroke={Colors.darkGreyText}
                            strokeWidth={1}
                            strokeDasharray="5 5" // Dashed line pattern (5px dash, 5px gap)
                            dot={false} // Disable dots entirely for this line
                        />
                        <CartesianGrid stroke="#ddd" />
                        <XAxis
                            dataKey="name"
                            style={{ fontSize: 14 }}
                            tickFormatter={(tick) => tick.split(',')[0]} // Remove year from the x-axis label
                            axisLine={{ stroke: '#000', strokeWidth: 1 }} // Show x-axis line
                            tickLine={false} // Hide tick marks
                        />
                        <YAxis />
                        <Tooltip
                            formatter={(value, name) => [
                                `${value}`,
                                `${capitalize(name.replace('_', ' ').replace('_', ' '))}`,
                            ]}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        )
    }

    const renderInsightCard = () => {
        const report_text = analyticsV2 && analyticsV2.insight ? analyticsV2.insight : 'No insights available';
        return (
            <Grid item xs={12} md={12}>
                <Paper variant="outlined" sx={{ position: 'relative', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', padding: '16px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
                            <div style={CARD_SUB_HEADER_STYLE}>How Is My Website Doing?</div>
                            <MuiTooltip title={INSIGHT_INFO}>
                                <InfoOutlined style={{ fontSize: 18, cursor: 'pointer', marginTop: 3 }} />
                            </MuiTooltip>
                        </Box>
                        
                        <p>{report_text}</p>
                    </Box>
                </Paper>
            </Grid>
        )
    }


    const renderChartCard = (title, chart, shrink = false) => (
        <Grid item xs={12} md={shrink && 8}>
            <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
                    <div style={CARD_SUB_HEADER_STYLE}>{title}</div>
                </Box>
                {chart}
            </Paper>
        </Grid>
    )

    const renderOverviewSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Overview</h5>
            </Grid>
            {renderInsightCard()}
            <GenericInfoCard title={"Active Users"} content={analyticsV2.statistics.TARGET.active_users_total} subcontent={renderPercentageSection(analyticsV2.statistics.TARGET.active_users_total, analyticsV2.statistics.COMPARE ? analyticsV2.statistics.COMPARE.active_users_total : 0)} footer={`over the ${PastDayChoicesLabels[pastDayView]}`.toLocaleLowerCase()} info={ACTIVE_USERS_INFO}/>
            <GenericInfoCard title={"New Users"} content={analyticsV2.statistics.TARGET.new_users_total} subcontent={renderPercentageSection(analyticsV2.statistics.TARGET.new_users_total, analyticsV2.statistics.COMPARE ? analyticsV2.statistics.COMPARE.new_users_total : 0)} footer={`over the ${PastDayChoicesLabels[pastDayView]}`.toLocaleLowerCase()} info={NEW_USERS_INFO}/>
            <GenericInfoCard title={"Average Visit Time"} content={formatTime(analyticsV2.statistics.TARGET.session_time_avg)} subcontent={renderPercentageSection(analyticsV2.statistics.TARGET.session_time_avg, analyticsV2.statistics.COMPARE ? analyticsV2.statistics.COMPARE.session_time_avg : 0)} footer="minutes per user" info={AVG_TIME_INFO}/>
        </Grid>
    )

    const renderWebsiteTrafficSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '4px' }}>
            {renderChartCard(`Active Users Over Time`, renderVisitorChart(), false)}
        </Grid>
    )

    const renderMoreMetricsSection = () => (
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
            <Grid item xs={12}>
                <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>More Metrics</h5>
            </Grid>
            <GenericInfoCard title={"Bounce Rate"} content={`${(analyticsV2.statistics.TARGET.bounce_rate_avg * 100).toFixed(1)}%`} subcontent={renderPercentageSection(analyticsV2.statistics.TARGET.bounce_rate_avg, analyticsV2.statistics.COMPARE ? analyticsV2.statistics.COMPARE.bounce_rate_avg : 0, true)} footer={`over the ${PastDayChoicesLabels[pastDayView].toLocaleLowerCase()}`} info={BOUNCE_RATE_INFO}/>
            {renderUptimeCard()}
        </Grid>
    )

    const renderUptimeCard = () => {

        const uptime = analyticsV2 && analyticsV2.uptime ? analyticsV2.uptime : undefined;
        const status = uptime ? uptime.status : '---';
        const percent = uptime ? Number(analyticsV2.uptime.custom_uptime_ratio) : 100;

        return <GenericInfoCard title={"Website Status"} content={status} subcontent={<StyledPercentageChip value={percent.toFixed(2)} />} footer='uptime over the last 30 days' info={UPTIME_INFO}/>
    }

    const renderSetupContent = () => {
        return (
            <Grid container justifyContent="center" sx={{ marginTop: '10%'}}>
                <Grid item xs="auto">
                    <Paper sx={{ padding: '20px', maxWidth: '600px' }}>
                        <h4 style={{ marginTop: '10px', marginBottom: '10px'}}>Not Enabled...</h4>
                        <p>Your website activity & analytics aren't turned on for your account yet. If you'd like to get them set up, please let us know!</p>
                        <Box sx={{ marginTop: '50px', display: 'flex' }}>
                            <ContainedButton sx={{ marginRight: '10px' }} onClick={() => window.open('https://consultwithkyle.com/contact-us', '_blank')}>
                                Contact Us
                            </ContainedButton>
                            <TextButton onClick={() => history.goBack()}>
                                Go Back
                            </TextButton>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        )
    }

    // KEEP FOR NEXT VERSION
    const renderBannerSection = () => (
        <Grid container justifyContent="space-between" sx={{ marginTop: '28px' }}>
            <Grid item xs='auto' sx={{ height: '65px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2}}>
                    <TabButton isActive={pageInView === PageViews.ANALYTICS}>Analytics</TabButton>
                    <TabButton isActive={pageInView === PageViews.TRENDING}>Trending</TabButton>
                    <TabButton isActive={pageInView === PageViews.INSIGHTS}>Insights</TabButton>
                </Box>
            </Grid>
            <Grid item xs='auto'>
                <StyledDropDown
                    label="Date Range"
                    value={pastDayView}
                    onChange={(e) => changeDateRange(e.target.value)}
                    sx={{ minWidth: '250px'}}
                >
                    {Object.keys(PastDayChoicesLabels).map(key => (
                        <MenuItem
                            key={key}
                            value={key}
                        >
                            {PastDayChoicesLabels[key]}
                        </MenuItem>
                    ))}
                </StyledDropDown>
            </Grid>
        </Grid>
    )

    const renderProperView = () => {
        if (isLoading) {
            return renderLoadingContent();
        } else if (!analyticsV2 || !analyticsV2.enabled) {
            return renderSetupContent();
        } else {
            return (
                <>
                    {/* renderBannerSection() */}
                    {renderOverviewSection()}
                    {renderWebsiteTrafficSection()}
                    {renderMoreMetricsSection()}
                </>
            )
        }
    }
    
    return (
        <Grid container>
          <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
            <Grid container justifyContent="space-between" >
                <Grid item xs='auto'>
                    <StyledSubHeader title="Activity" />
                </Grid>
                <Grid item xs='auto'>
                    <StyledDropDown
                        label="Date Range"
                        value={pastDayView}
                        onChange={(e) => changeDateRange(e.target.value)}
                        sx={{ minWidth: '250px'}}
                    >
                        {Object.keys(PastDayChoicesLabels).map(key => (
                            <MenuItem
                                key={key}
                                value={key}
                            >
                                {PastDayChoicesLabels[key]}
                            </MenuItem>
                        ))}
                    </StyledDropDown>
                </Grid>
            </Grid>
            {renderProperView()}
          </Grid>
      </Grid>
    )
}

export default withRouter(ViewSiteActivityComponent);
