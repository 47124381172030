import SitesStore from "../stores/internal/SitesStore";
import { toJS } from "mobx";

export const getAllSites = () => toJS(SitesStore.sites);
export const getSiteById = (site_id) => toJS(SitesStore.siteById[site_id])
export const getSiteAnalyticsById = (site_id) => toJS(SitesStore.site_analytics[site_id])
export const getSiteAnalyticsV2ByIdAndRange = (site_id, date_range) => {
  if (!SitesStore.site_analytics_v2[site_id]) {
    return undefined;
  }
  return toJS(SitesStore.site_analytics_v2[site_id][date_range])
}
export const getSiteInvoiceReportById = (site_id) => toJS(SitesStore.site_invoice_reports[site_id])
export const getSiteSubscriptionById = (site_id) => toJS(SitesStore.site_subscriptions[site_id])
export const getSiteHoursReportByIdAndTime = (site_id, start, end) => {
  if (!SitesStore.site_hour_reports[site_id]) {
    return undefined;
  }
  return toJS(SitesStore.site_hour_reports[site_id][`${start}|${end}`])
}
export const getSiteSubmissionsById = (site_id) => toJS(SitesStore.site_submissions[site_id])
