import { Box, Grid, Paper, Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import React from "react";
import { CARD_SUB_HEADER_STYLE, SUBCONTENT_INFO_FONT } from "../../../../constants/Styles";

const GenericInfoCard = ({
  title, content, subcontent, footer, info
}) => {

return (
  <Grid item xs={12} md={4}>
    <Paper variant="outlined" sx={{ padding: '16px', position: 'relative' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
        <div style={CARD_SUB_HEADER_STYLE}>{title}</div>
        {info !== undefined &&
          <Tooltip title={info}>
            <InfoOutlined style={{ fontSize: 18, cursor: 'pointer', marginTop: 3 }} />
          </Tooltip>
        }
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', zIndex: 15 }}>
        <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: '20px', fontSize: '40px' }}>{content}</h4>
        <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          {subcontent}
          <p style={{ textAlign: 'right', fontSize: '13px', padding: 0, paddingTop: '4px', margin: 0, color: '#777' }}>{footer}</p>
        </Box>
      </Box>
    </Paper>
  </Grid>
)
}

export default GenericInfoCard;
