import React, { useState } from "react";
import { Grid, Paper, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction } from '@mui/material';
import Colors from "../../../../constants/Colors";
import { AccessTimeOutlined, CloudOutlined, Edit, LocationOn, PersonOutline, ReceiptOutlined, Store, Web } from "@mui/icons-material";
import IconTextSection from "../../../common/IconTextSection";
import { withRouter } from "react-router";
import StyledSubHeader from "../../../common/StyledSubHeader";
import { ContainedButton, TooltipIconButton } from "../../../common/StyledButtons";
import { CARD_HEADER_STYLE } from "../../../../constants/Styles";
import { getSiteById } from "../../../../selectors/SiteSelectors";
import StyledChip from "../../../common/StyledChip";
import { getIsUserAdmin } from "../../../../selectors/UserSelectors";
import EditSiteAccessModal from "../../modals/EditSiteAccessModal";

const SiteAccountDetails = ({ history, match }) => {

  const [showEditAccessModal, setShowEditAccessModal] = useState(false);

  const siteId = match.params.site_id;
  const site = getSiteById(siteId);

  const prepareActions = () => {
    const tools = [
      {
        name: "Invoices",
        icon: <ReceiptOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
        description: 'See past and current invoices for your business.',
        link: `/dashboard/site/${siteId}/billing`
      },
      {
        name: "Consulting Rates",
        icon: <AccessTimeOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
        description: 'View the hourly rates for consulting with BCK.',
        link: `/dashboard/site/${siteId}/consulting`
      },
      {
        name: "My Services",
        icon: <CloudOutlined sx={{ color: Colors.primaryColor, fontSize: '64px' }} />,
        description: 'Review your current and available services.',
        link: `/dashboard/site/${siteId}/package`
      }
    ]
    return tools
  }

  const renderWebsite = () => {
    if (site && site.site_service_modules && site.site_service_modules.website && site.site_service_modules.website.domain )
    return <a style={{ color: Colors.primaryColor }} href={`https://${site.site_service_modules.website.domain.url}`} target='_blank'>{site.site_service_modules.website.domain.url}</a>
    else return <i>Not Available</i>
  }

  const renderQuickActions = () => prepareActions().map((action) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={action.name}>
      <Paper variant="outlined" sx={{ padding: '16px', position: 'relative', minHeight: '200px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
           {action.icon}
        </Box>
        <Box>
          <div style={{ ...CARD_HEADER_STYLE, textAlign: 'center' }}>{action.name}</div>
          <p style={{ textAlign: 'center' }}>{action.description}</p>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
          <ContainedButton onClick={() =>  history.push(action.link)} sx={{ padding: '8px 16px 8px 16px' }}>View</ContainedButton>
        </Box>
      </Paper>
    </Grid>
  ))

  const renderAccessContent = () => (
    <Grid item xs={12}>
      <Paper variant="outlined" style={{ padding: '24px 16px 24px 16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <h4 style={{ margin: 0, fontSize: '24px' }}>Accounts</h4>
          {(site.site_security.is_primary_user || getIsUserAdmin())
          && <TooltipIconButton
            text="Manage User Access"
            icon={<Edit sx={{ fontSize: '24px' }}/>}
            onClick={() => setShowEditAccessModal(true)}
          /> }
        </Box>
        <List style={{ flex: 1 }}>
          {site && site.site_security.accessible_users.map((user) => (
              <ListItem
                  dense
                  sx={{ backgroundColor: '#fff', marginBottom: '5px' }}>
                  <ListItemAvatar>
                    <Avatar><PersonOutline /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
                  {user.is_primary_user && (<ListItemSecondaryAction>
                    <StyledChip text="Primary" />
                  </ListItemSecondaryAction>
                  )}
              </ListItem>
          ))}
        </List>
      </Paper>
    </Grid>
  )

  return (
    <Grid container>
      <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
        <StyledSubHeader title="My Business" />
        <Grid container justify='flex-start' spacing={2} sx={{ marginTop: '6px' }}>
          <Grid item xs={12}>
            <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Account Information</h5>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" style={{ padding: 16 }}>
              <h4 style={{ margin: 0, fontSize: '24px' }}>Overview</h4>
              <IconTextSection
                  icon={<Store sx={{ fontSize: '24px' }}/>}
                  primaryText={`${site.site_details.site_name}${`, ${site.site_details.site_structure}` || ''}`}
                />
              <IconTextSection
                  icon={<Web sx={{ fontSize: '24px' }}/>}
                  primaryText={renderWebsite()}
                />
              <IconTextSection
                icon={<LocationOn sx={{ fontSize: '24px' }}/>}
                primaryText={ site.site_details.site_address && site.site_details.site_address.address_line_1 ? <span>{site.site_details.site_address.address_line_1} {site.site_details.site_address.address_line_2}</span> : <i>Not Provided</i> }
                secondaryText={ site.site_details.site_address && site.site_details.site_address.address_line_1 ? <span>{site.site_details.site_address.city} {site.site_details.site_address.state}, {site.site_details.site_address.zipcode}</span> : '' }
              />
            </Paper>
          </Grid> 
          <Grid item xs={12}>
            <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Quick Actions</h5>
          </Grid>
          {renderQuickActions()}
          <Grid item xs={12}>
            <h5 style={{ margin: 0, paddingTop: '20px', paddingBottom: '10px' }}>Access</h5>
          </Grid>
          {renderAccessContent()}
        </Grid>
      </Grid>
      <EditSiteAccessModal
        open={showEditAccessModal}
        onClose={() => setShowEditAccessModal(false)}
        site={site}
      />
    </Grid>
  )
}

export default withRouter(SiteAccountDetails);
