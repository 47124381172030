import { Grid } from '@mui/material';
import React from "react";
import SiteDetailsCard from "./cards/SiteDetailsCard";
import ActivityCard from "./cards/ActivityCard";
import InvoicesCard from "./cards/InvoicesCard";
import ConsultingCard from "./cards/ConsultingCard";
import ProjectsCard from "./cards/ProjectsCard";
import SiteToolsCard from './cards/SiteToolsCard';

const WebsiteViewComponent = () => {

    return (
      <Grid container>
          <Grid item xs={12} sm={10} md={9} sx={{ marginTop: '16px' }}>
            <Grid container justify='flex-start' spacing={2}>
              <Grid item xs={12} sx={{marginBottom: '18px'}}>
                <h6>Overview</h6>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
                <SiteDetailsCard />
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ActivityCard />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <InvoicesCard />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <ConsultingCard />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{marginTop: '36px', marginBottom: '18px'}}>
              <h6>My Projects</h6>
            </Grid>
            <ProjectsCard />
            <Grid item xs={12} sx={{marginTop: '36px', marginBottom: '18px'}}>
              <h6>Tools</h6>
            </Grid>
            <SiteToolsCard />
          </Grid>
      </Grid>
    )
}

export default WebsiteViewComponent;
