import {Button, CircularProgress, IconButton, Tooltip} from "@mui/material";
import React from "react";
import {CONTAINED_BUTTON_STYLE, NAV_TEXT_BUTTON_STYLE, TEXT_BUTTON_STYLE, TAB_BUTTON_STYLE} from "../../constants/Styles";
import Colors from "../../constants/Colors";

export const NavTextButton = ({
 children,
 ...props
}) => (
  <Button
    sx={NAV_TEXT_BUTTON_STYLE}
    variant="text"
    {...props}
  >
    {children}
  </Button>
)

export const TextButton = ({
 children,
 sx,
 ...props
}) => (
  <Button
    sx={{ ...TEXT_BUTTON_STYLE, ...sx }}
    variant="text"
    {...props}
  >
    {children}
  </Button>
)

export const ContainedButton = ({
 children,
  sx,
 ...props
}) => (
  <Button
    sx={{ ...CONTAINED_BUTTON_STYLE, ...sx }}
    variant="contained"
    { ...props }
  >
    {children}
  </Button>
)

export const TabButton = ({
  children,
   sx,
   isActive,
  ...props
 }) => {
    const extraStyle = isActive ? { backgroundColor: Colors.primaryColor, color: '#fff' } : {};
    return (<Button
      sx={{ ...TAB_BUTTON_STYLE, ...sx, ...extraStyle }}
      variant="text"
      { ...props }
    >
      {children}
    </Button>)
 }

export const LoadingButton = ({
  isLoading,
  children,
  ...props
}) => (
  <Button variant="contained" sx={CONTAINED_BUTTON_STYLE} {...props}>
    { isLoading ?
      <CircularProgress style={{ color: Colors.lightColor, marginLeft: 10, marginRight: 10 }} thickness={3} size={20} />
      : children
    }
  </Button>
)

export const TooltipIconButton = ({
  icon,
  text,
  ...props
}) => (
  <Tooltip title={text}>
    <IconButton {...props}>{icon}</IconButton>
  </Tooltip>
)
